import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
  isAndroidDevice,
  isIOSDevice,
  preloadImagePaths,
  TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH,
  TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH,
  getCertOptInNameFromQueryString,
  getCertOptInEmailAddressFromQueryString,
  getCertOptInIsPrefilledFromQueryString,
  getCoachProfileImageUrl,
  getCoachDiscoveryQuizUrl,
  trimHttpPrefix,
  getCurrentYear,
  recordLinkClickAndRedirect,
} from '../../utils'

import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import PieChartInsightSection from './PieChartInsightSection'
import AppReviewPanel from './AppReviewPanel'
import LineGraph from './LineGraph'
import MembershipFeatureDescription from './MembershipFeatureDescription'
import SendRequestButton from './SendRequestButton'
import AffiliateButtonsSection from './AffiliateButtonsSection'
import PublicClientTestimonialViewer from './PublicClientTestimonialViewer'

import CoachSessionPaymentResultModal from '../modals/CoachSessionPaymentResultModal'

import agent from '../../agent'

import {
  NeuroFitNamedLogoSvg,
  NeuroFitLogoSvg,
  VerifiedSvg,
  ExternalLinkSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_CLICKED_COACH_DISCOVERY_QUIZ_LINK,
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
} from '../../constants/requestStates'

import {flag} from 'country-emoji'

// import {

// } from '../../constants/experimentFlags'

import loadable from '@loadable/component'


const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}

const CoachSessionStripeCheckoutForm = loadable(() => import('./CoachSessionStripeCheckoutForm'))


class CoachProfileDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: false,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      firstName: getCertOptInNameFromQueryString(),
      emailAddress: getCertOptInEmailAddressFromQueryString(),
      isFormPrefilled: getCertOptInIsPrefilledFromQueryString(),
      isAndroid: isAndroidDevice(),
      isIOS: isIOSDevice(),
      isProfileImageLoaded: false,
      purchasedSessionQuantity: 1,
      checkoutEmailAddress: "",
      selectedClientTestimonialIndex: 0
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const {hasScrolled} = this.state

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, hasScrolled: true, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    await initializePixelIfAvailable(true/*initTikTok*/)

    const innerWidth = document.body.clientWidth || window.innerWidth
    const isMobile = innerWidth <= MinDesktopWidthPixels

    await preloadImagePaths([isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH])
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      experimentFlags,
      coachProfileDetails,
      onFetchCoachProfileDetails,
      fetchCoachProfileDetailsRequestResult,
      isValidPersonalUrl
    } = this.props


    const {show, purchasedSessionQuantity, checkoutEmailAddress, showPaymentResultModal, paymentSuccess, selectedClientTestimonialIndex, isProfileImageLoaded, hasScrolled, isAndroid, isIOS, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const profileImageVersion = coachProfileDetails.profile_image_version
    const profileImageSrc = (!!profileImageVersion) ? getCoachProfileImageUrl(coachProfileDetails.uuid, profileImageVersion) : undefined
    const hasProfileImage = (!!profileImageSrc)
    const countryCode = coachProfileDetails.profile_location_country_code

    const yearsOfExperience = coachProfileDetails.years_professional_experience

    const clientTestimonials = coachProfileDetails.client_testimonials

    const currentYear = getCurrentYear()

    return (
      <div 
        id={"coach-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{textAlign: "start", width: "100vw", minHeight: innerHeight, backgroundColor: "#CFD2DD"}}>
          <div style={{display: "flex", justifyContent: "center", minHeight: innerHeight, height: "100%", width: "100%"}} ref={elem => this.signUpRef = elem}>
            <div style={{display: "inline-block", width: "100%", maxWidth: 600, backgroundColor: "#EDEEF2"}}>
              <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <div style={{width: "100%", position: "relative"}}>
                  <div style={{position: "absolute", top: 0, left: 0, right: 0}}>
                    <div style={{position: "relative", width: "100%", height: 130, backgroundColor: "#000000"}}>
                    </div>
                    <div style={{position: "absolute", top: "min(5vw, 20px)", width: "100%"}}>
                      <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                        <div style={{display: "flex", alignItems: "top", justifyContent: "space-between", width: "85%"}}>
                          <div style={{height: 160, width: 160, borderRadius: "50%", backgroundColor: "#FFFFFF", boxShadow: profileImageSrc ? "0px 0.5px 4px 0px #A5A8B0" : "0px 0px 1.5px 0.5px #A5A8B0"}}>
                            <div style={{height: 150, width: 150, opacity: (!hasProfileImage || isProfileImageLoaded) ? 1 : 0, margin: 5, borderRadius: "50%", backgroundColor: "#CFD2DD", transition: "opacity 150ms linear"}}>
                              {!!(profileImageSrc) ? (
                                <img src={profileImageSrc} onLoad={() => this.setState({isProfileImageLoaded: true})} style={{height: "100%", width: "100%", borderRadius: "50%", boxShadow: "0px 0px 0.5px 1px #A5A8B0"}} />
                              ) : (
                                <NeuroFitLogoSvg
                                  size={"150px"}
                                  color={"#000000"}
                                  lineColor={"#FFFFFF"}
                                  margin={"0px 0px 0px 0px"}
                                />
                              )}
                            </div>
                          </div>
                          <NeuroFitNamedLogoSvg
                            logoSize={"min(5vw, 20px)"}
                            color={"#FFFFFF"}
                            onClick={() => {
                              window.open(ProductionBaseUrl, "_blank", "noopener noreferrer")
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <div style={{width: "85%", maxWidth: 600, marginTop: "4vh"}}>
                  <div style={{maxWidth: 600, paddingTop: 150, fontSize: "min(5vw, 20px)", marginTop: "2vh", paddingBottom: "5vh"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "start"}}>
                      <div>
                        <div style={{color:  "#000000", fontFamily: "Futura Medium", textTransform: "uppercase", fontSize: "min(7vw, 30px)", lineHeight: "min(7vw, 30px)"}}>
                          {`${coachProfileDetails.first_name} ${coachProfileDetails.last_name}`}
                        </div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "0.5vh"}}>
                          <VerifiedSvg size={"min(4.5vw, 18px)"} color={"#000000"} />
                          <div style={{color: "#000000", textTransform: "uppercase", marginLeft: "0.2em", fontFamily: "Futura Medium", fontSize: "min(4.5vw, 18px)"}}>
                            {"Certified Nervous System Coach"}
                          </div>
                        </div>
                        <div style={{color: "#777777", fontFamily: "Futura", fontSize: "min(4.5vw, 20px)", lineHeight: "min(4.5vw, 20px)", fontFamily: "Futura", marginTop: "0.75vh"}}>
                          {`${(yearsOfExperience > 0) ? `${yearsOfExperience}-Year ` : ""}${coachProfileDetails.occupation}${!!flag(countryCode) ? ` ${flag(countryCode)}` : ""}`}
                        </div>
                      </div>
                    </div>
                    {/*<div style={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "1.25vh"}}>
                      <span style={{textTransform: "uppercase", display: "inline-block", color: "#999999", marginInlineEnd: "0.3em", textUnderlineOffset: "0.18em", textDecorationThickness: "0.1em", fontFamily: "Futura Medium", fontSize: "min(3.5vw, 16px)", lineHeight: "min(3.5vw, 16px)"}}>
                        {`$${(coachProfileDetails.hourly_rate_usd_cents / 100).toFixed(0)}/hour`}
                      </span>
                      {isValidPersonalUrl && (
                        <span style={{textTransform: "uppercase", display: "inline-block", color: "#999999", marginInlineEnd: "0.3em", fontFamily: "Futura Medium", fontSize: "min(5vw, 20px)", lineHeight: "min(3.5vw, 16px)"}}>
                          {`•`}
                        </span>
                      )}
                      {isValidPersonalUrl && (
                        <a href={coachProfileDetails.workplace_url} rel="nofollow noreferrer noopener" target="_blank" style={{textTransform: "uppercase", display: "inline-block", color: "#999999", textDecoration: "underline", textUnderlineOffset: "0.18em", textDecorationThickness: "0.1em", fontFamily: "Futura Medium", fontSize: "min(3.5vw, 16px)", lineHeight: "min(3.5vw, 16px)"}}>
                          <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                            <span style={{maxWidth: "min(75vw, 300px)", overflow: "hidden", padding: "3px 0px", textOverflow: "ellipsis"}}>
                              {"My Website"}
                            </span>
                            <div style={{display: "inline-block", height: "min(3.5vw, 16px)", width: "min(3.5vw, 16px)", marginInlineStart: "0.3em"}}>
                              <ExternalLinkSvg size={"min(3vw, 12px)"} color={"#999999"} />
                            </div>
                          </div>
                        </a>
                      )}
                    </div>*/}
                    <button
                      style={{display: "flex", pointerEvents: "all", textTransform: "uppercase", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 500, textAlign: "center", fontFamily: "Futura", fontSize: "min(5vw, 20px)", lineHeight: "min(5vw, 20px)", marginTop: "min(32px, 3vh)", color: "#ffffff", backgroundColor: "#000000", padding: "12px", border: "none"}}
                      onClick={() => {
                        const redirectUrl = getCoachDiscoveryQuizUrl(coachProfileDetails.uuid)
                        recordLinkClickAndRedirect(GA_LABEL_CLICKED_COACH_DISCOVERY_QUIZ_LINK, redirectUrl)
                      }}
                    >
                      {"Take My Discovery Quiz"}
                    </button>
                  </div>
                </div>
              </div>
              <div style={{backgroundColor: "#CFD2DD", height: 6, boxShadow: "0px 2px 1.5px -1.5px #A5A8B0 inset, 0px -2px 1.5px -1.5px #A5A8B0 inset"}}>
              </div>
              <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "5vh 0px"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <div style={{fontSize: "min(7vw, 30px)", textTransform: "uppercase", fontFamily: "Futura", marginBottom: "2vh"}}>
                    {coachProfileDetails.coaching_niche_title}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Book", whiteSpace: "pre-line", paddingBottom: "1vh", minHeight: 100, fontSize: "min(5.5vw, 22px)", textAlign: "start"}}>
                    {coachProfileDetails.coaching_niche_description}
                  </div>
                </div>
              </div>
              <div style={{backgroundColor: "#CFD2DD", height: 6, boxShadow: "0px 2px 1.5px -1.5px #A5A8B0 inset, 0px -2px 1.5px -1.5px #A5A8B0 inset"}}>
              </div>
              <div style={{display: "flex", justifyContent: "center", width: "100%", margin: "5vh 0px"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <div style={{fontSize: "min(7vw, 30px)", textTransform: "uppercase", fontFamily: "Futura", marginBottom: "2vh"}}>
                    {coachProfileDetails.background_experience_title}
                  </div>
                  <div style={{marginTop: 10, fontFamily: "Futura Book", whiteSpace: "pre-line", paddingBottom: "1vh", minHeight: 100, fontSize: "min(5.5vw, 22px)", textAlign: "start"}}>
                    {coachProfileDetails.background_experience_description}
                  </div>
                </div>
              </div>
{/*              {(!!(clientTestimonials) && clientTestimonials.length > 0) && (
                <div>
                  <div style={{backgroundColor: "#CFD2DD", height: 6, boxShadow: "0px 2px 1.5px -1.5px #A5A8B0 inset, 0px -2px 1.5px -1.5px #A5A8B0 inset"}}>
                  </div>
                  <div style={{margin: "5vh 0px"}}>
                    <div style={{display: "flex", justifyContent: "center", width: "100%", marginBottom: "2.5vh"}}>
                      <div style={{width: "85%", maxWidth: 500}}>
                        <div style={{fontSize: "min(7vw, 30px)", textTransform: "uppercase", fontFamily: "Futura"}}>
                          {"VERIFIED TESTIMONIALS"}
                        </div>
                      </div>
                    </div>
                    <PublicClientTestimonialViewer
                      testimonials={clientTestimonials}
                      selectedIndex={selectedClientTestimonialIndex}
                      onUpdateIndex={idx => this.setState({selectedClientTestimonialIndex: idx})}
                    />
                  </div>
                </div>
              )}*/}
              <div style={{backgroundColor: "#CFD2DD", height: 6, boxShadow: "0px 2px 1.5px -1.5px #A5A8B0 inset, 0px -2px 1.5px -1.5px #A5A8B0 inset"}}>
              </div>
              {/*{coachProfileDetails.is_client_session_booking_enabled ? (
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", margin: "5vh 0px"}}>
                  <div style={{width: "85%", maxWidth: 500}}>
                    <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                      {"Book 1-On-1 Sessions"}
                    </div>
                    <CoachSessionStripeCheckoutForm
                      isNative={isNative}
                      hourlyPriceUsdCents={coachProfileDetails.hourly_rate_usd_cents}
                      isDiscountedSessionBundlingEnabled={coachProfileDetails.is_discounted_session_bundling_enabled}
                      coachUsername={coachProfileDetails.username}
                      coachFirstName={coachProfileDetails.first_name}
                      coachLastName={coachProfileDetails.last_name}
                      onPaymentSuccess={({purchasedSessionQuantity, checkoutEmailAddress}) => this.setState({purchasedSessionQuantity, checkoutEmailAddress, showPaymentResultModal: true, paymentSuccess: true})}
                      onShowErrorMessage={() => this.setState({showPaymentResultModal: true})}
                    />
                  </div>
                </div>
              ) : (
                <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", margin: "5vh 0px"}}>
                  <div style={{width: "85%", maxWidth: 500}}>
                    <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                      {"1-On-1 Client Sessions"}
                    </div>
                    <div style={{marginTop: 10, fontFamily: "Futura Book", whiteSpace: "pre-line", paddingBottom: "1vh", minHeight: 100, fontSize: "min(5.5vw, 22px)", textAlign: "start"}}>
                      {`${coachProfileDetails.first_name}'s coaching sessions are priced at $${coachProfileDetails.hourly_rate_usd_cents/100} USD per hour. They're currently not accepting new 1-on-1 clients - please check back soon.`}
                    </div>
                  </div>
                </div>
              )}
              {coachProfileDetails.is_client_session_booking_enabled && (
                <div style={{backgroundColor: "#CFD2DD", height: 6, boxShadow: "0px 2px 1.5px -1.5px #A5A8B0 inset, 0px -2px 1.5px -1.5px #A5A8B0 inset"}}>
                </div>
              )}*/}
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", margin: "5vh 0px"}}>
                <div style={{width: "85%", maxWidth: 500}}>
                  <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(7.5vw, 32px)", textAlign: "start"}}>
                    {"Get 20% Off"}
                  </div>
                  <AffiliateButtonsSection
                    affiliateCode={coachProfileDetails.affiliate_code}
                    isIOSDevice={isIOSDevice}
                    isAndroidDevice={isAndroidDevice}
                    maxWidth={400}
                  />
                </div>
              </div>
              <div style={{paddingBottom: 15 + safeAreaInsets.bottom, paddingTop: "10vh", paddingInlineStart: 15, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", "justifyContent": "left"}}>
                <span style={{color: "#c1c4d2", lineHeight: "min(3.5vw, 16px)"}}>
                  {`© NEUROFIT ${currentYear}`}
                </span>
                <span style={{display: "inline-block", fontFamily: "Futura Medium", color: "#c1c4d2", margin: "0px 0.2em", lineHeight: "min(4.5vw, 18px)", fontSize: "min(4.5vw, 18px)"}}>
                  {"•"}
                </span>
                <span style={{color: "#c1c4d2", lineHeight: "min(3.5vw, 16px)"}}>
                  {"ALL RIGHTS RESERVED"}
                </span>
              </div>
            </div>
            <div style={{height: "3vh"}}>
            </div>
          </div>
        </div>
        <CoachSessionPaymentResultModal
          show={showPaymentResultModal}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          paymentSuccess={paymentSuccess}
          coachProfileDetails={coachProfileDetails}
          purchasedSessionQuantity={purchasedSessionQuantity}
          checkoutEmailAddress={checkoutEmailAddress}
          onClose={() => this.setState({showPaymentResultModal: false})}
        />
      </div>
    )
  }
}

export default CoachProfileDetails