import React from 'react';

import { 
  subdomainHosts,
  subdomainUrls,
  NeuroFitCompletionSoundAsset,
  NeuroFitCompletionSoundFilepath,
  DailyInsightNotificationChannelId,
  MinCompletionSoundVolumeLevel,
  resizeInnerHeight,
  resizeInnerWidth,
  asyncCallWithTimeout,
  recordFunnelEventGA,
  HRV_MEASUREMENT_PROGRESS_25_PERCENT,
  HRV_MEASUREMENT_PROGRESS_50_PERCENT,
  HRV_MEASUREMENT_PROGRESS_75_PERCENT,
  HRV_MEASUREMENT_PROGRESS_100_PERCENT,
  lazyLoadSentryLogging,
  ProductionCertificationUrl,
  delay,
  initializeSentry,
  captureSentryException,
  isGDPRSensitive,
  initializeGA,
  configureAnalyticsUserProperties,
  hideLoadingScreen
} from '../utils'

import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// import { polyfill } from "seamless-scroll-polyfill";
import smoothscroll from 'smoothscroll-polyfill';

import LandingPageContainer from '../containers/LandingPageContainer'
// import CoursePageContainer from '../containers/CoursePageContainer'
// import NPlusPageContainer from '../containers/NPlusPageContainer'
import CertificationPageContainer from '../containers/CertificationPageContainer'
// import MasterCoachCertificationPageContainer from '../containers/MasterCoachCertificationPageContainer'
import CertificationVideoPageContainer from '../containers/CertificationVideoPageContainer'
import TeamsOptInPageContainer from '../containers/TeamsOptInPageContainer'
import CertificationVslPageContainer from '../containers/CertificationVslPageContainer'
// import MasterCoachWaitListPageContainer from '../containers/MasterCoachWaitListPageContainer'
import CoachAgentsWaitListPageContainer from '../containers/CoachAgentsWaitListPageContainer'
// import MasterCoachCalendarBookingPageContainer from '../containers/MasterCoachCalendarBookingPageContainer'
// import MastermindCalendarBookingPageContainer from '../containers/MastermindCalendarBookingPageContainer'
import RedirectContainer from '../containers/RedirectContainer'
import AboutPageContainer from '../containers/AboutPageContainer'
import LinksPageContainer from '../containers/LinksPageContainer'
import LorenPageContainer from '../containers/LorenPageContainer'
import TrainingRegistrationPageContainer from '../containers/TrainingRegistrationPageContainer'
import SupportPageContainer from '../containers/SupportPageContainer'
import WebAppCheckoutPageContainer from '../containers/WebAppCheckoutPageContainer'
import CoachProfilePageContainer from '../containers/CoachProfilePageContainer'
import LearnSitemapPageContainer from '../containers/LearnSitemapPageContainer'
import LearnLocalizedSitemapPageContainer from '../containers/LearnLocalizedSitemapPageContainer'

// import LoloPreLaunchVideoPageContainer from '../containers/LoloPreLaunchVideoPageContainer'


import {
  commonUpdateNativeAppOpenCount,
  commonTriggerRequest,
  commonUpdatePushNotificationToken,
  commonUpdateDeepLinkedArticleKey,
  commonUpdateShowMembershipModalIfNeeded,
  commonUpdateShortcutItemType,
  commonUpdateInnerHeight,
  commonUpdateInnerWidth,
  commonUpdateSafeAreaInsets,
  commonUpdateCameraPermissions,
  commonUpdateAppActionItemType,
  commonUpdateAppStateIsActive,
  commonUpdateCurrentAppVersion,
  commonUpdateNativeCompletionSoundPreloaded,
  commonUpdateIsDeeplinkedPaywallAction,
  commonUpdateIsDeeplinkedDiscoveryQuizAction,
  commonUpdateDeepLinkedCertificationAffiliateId,
  commonUpdateExperimentFlagsWeb,
  resetCommon,
} from '../actions/common';


import {
  loadUserExperimentFlagsWeb,
} from '../constants/experimentFlags'

import {
  COMMON_APP_OPEN_STORY_SHORT_CODE,
  COMMON_NATIVE_APP_OPEN_COUNT,
} from '../constants/commonState'

import {
  triggerRequest as loggedInPageTriggerRequest,
  loggedInPageUpdateHrvCaptureState,
  loggedInPageResetHrvCaptureState,
} from '../actions/loggedInPage'

import {
  HRV_CAPTURE_STATE_STATUS,
  HRV_CAPTURE_STATE_PROGRESS,
  HRV_CAPTURE_STATE_COMPLIANCE,
  HRV_CAPTURE_STATE_DATA,
  HRV_CAPTURE_STATE_FRAME_DROP_COMPLIANCE_ERROR_COUNT,
  HRV_CAPTURE_STATE_IS_RECONFIGURING_CAMERA,
} from '../constants/hrvCaptureState'

import {
  APP_ACTION_TYPE_LEARN,
  APP_ACTION_TYPE_BALANCE,
} from '../constants/appActionTypes'

import {
  REQ_LOGIN_USER,
  REQ_FETCH_APP_CONFIG,
  REQ_FETCH_DEPLOYMENT_CONFIG,
  REQ__ACTION_AUTO_REFRESH_APP,
  REQ_VALIDATE_RESET_PASSWORD_TOKEN,
  REQ_TRIGGER_HRV_ANALYSIS_TASK,
  REQ__ACTION_UPDATE_APP_BADGE_COUNT,
  REQ__ACTION_HANDLE_APP_ACTION,
  REQ__ACTION_PRESENT_DEEPLINKED_ARTICLE,
  REQ__ACTION_HANDLE_NOTIFICATION_ACTION_TYPE,
  REQ__ACTION_PRESENT_MEMBERSHIP_MODAL,
  REQ__ACTION_PRESENT_DISCOVERY_QUIZ_MODAL,
  REQ_SAVE_NOTIFICATION_REGISTRATION_RESULT,
  REQ__ACTION_OPEN_CREATORS_URL,
  REQ__ACTION_OPEN_CERTIFICATION_URL,
  REQ__ACTION_OPEN_TRAINING_REGISTRATION_URL,
} from '../constants/requestTypes'

import {
  GA_LABEL_HRV_MEASUREMENT_TRIGGERED_HRV_MEASUREMENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_25_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_50_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_75_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_100_PERCENT,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_COMPLETED,
  GA_LABEL_HRV_MEASUREMENT_HRV_MEASUREMENT_ERROR,
} from '../constants/gaEventLabels'


import ErrorBoundary from '../components/ErrorBoundary'

import loadable from '@loadable/component'

import 'rc-slider/assets/index.css';

const QuizAppContainer = loadable(() => import('../containers/QuizAppContainer'))
// const BlogHomepageContainer = loadable(() => import('../containers/BlogHomepageContainer'))
// const ResearchPageContainer = loadable(() => import('../containers/ResearchPageContainer'))
const ForceUpgradeAppPage = loadable(() => import('../components/ForceUpgradeAppPage'))
const LandingPageContainerV2 = loadable(() => import('../containers/LandingPageContainerV2'))
const BalancePageContainer = loadable(() => import('../containers/BalancePageContainer'))
const PurchaseConfirmationPageContainer = loadable(() => import('../containers/PurchaseConfirmationPageContainer'))
const AndroidAccountDeletionPageContainer = loadable(() => import('../containers/AndroidAccountDeletionPageContainer'))
const PlatformPageContainer = loadable(() => import('../containers/PlatformPageContainer'))

const LorenAuthorPageContainer = loadable(() => import('../containers/LorenAuthorPageContainer'))
const AndrewAuthorPageContainer = loadable(() => import('../containers/AndrewAuthorPageContainer'))

const safeAreaInsets = require('safe-area-insets')
require('intersection-observer');

// polyfill();

const mapStateToProps = state => ({state});

class WebsiteApp extends React.Component {

  resizeAppContainer() {
    const innerHeight = resizeInnerHeight()
    this.props.dispatch(commonUpdateInnerHeight(innerHeight))

    const innerWidth = resizeInnerWidth()
    this.props.dispatch(commonUpdateInnerWidth(innerWidth))
  }

  configureGlobalJavascriptEventListeners() {
    smoothscroll.polyfill();

    // Safe Area Insets
    safeAreaInsets.onChange(updatedValue => this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets)))
    this.props.dispatch(commonUpdateSafeAreaInsets(safeAreaInsets))

    window.addEventListener('resize', () => {
      this.resizeAppContainer()
    })
    
    window.addEventListener("pageshow", async () => {
      this.resizeAppContainer()
      this.props.dispatch(commonTriggerRequest(REQ_FETCH_DEPLOYMENT_CONFIG))
      this.props.dispatch(commonTriggerRequest(REQ__ACTION_AUTO_REFRESH_APP))
    })

    // Check for frontend updates whenever app is shown.
    document.addEventListener("visibilitychange", async (e) => {
      if (!document.hidden) {
        this.props.dispatch(commonTriggerRequest(REQ_FETCH_DEPLOYMENT_CONFIG))
        this.props.dispatch(commonTriggerRequest(REQ__ACTION_AUTO_REFRESH_APP))
      }
      this.resizeAppContainer()
    })
  }

  async componentWillMount() {
    try {
      // Resize the app container to fit the available size.
      this.resizeAppContainer()

      // Configure Global Window Event Listeners.
      this.configureGlobalJavascriptEventListeners()

      let webExperimentFlags;
      if (!isGDPRSensitive) {
        webExperimentFlags = await loadUserExperimentFlagsWeb()
        this.props.dispatch(commonUpdateExperimentFlagsWeb(webExperimentFlags))
      }
      hideLoadingScreen()

      await initializeGA(isGDPRSensitive/*disableCookies*/);
      await configureAnalyticsUserProperties({experimentFlags: webExperimentFlags})

      await initializeSentry()
    } catch (err) {
      hideLoadingScreen()
      console.error(`[INITIALIZE]: Initial Configuration Error: ${err}`)
      await initializeSentry()
      await captureSentryException(err)
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={LandingPageContainer} />
          <Route exact path="/product-demo" component={LandingPageContainerV2} />
          <Route exact path="/about" component={AboutPageContainer} />
          <Route exact path="/author/loren-hogue" component={LorenAuthorPageContainer} />
          <Route exact path="/author/andrew-hogue" component={AndrewAuthorPageContainer} />
          <Route exact path="/balance" component={BalancePageContainer} />
          <Route exact path="/links" component={LinksPageContainer} />
          <Route exact path="/loren" component={LorenPageContainer} />
          <Route exact path="/cert-offer" component={CertificationVideoPageContainer} />
          <Route exact path="/register" component={TrainingRegistrationPageContainer} />
          <Route exact path="/vsl" component={CertificationVslPageContainer} />
          <Route exact path="/certification" component={CertificationPageContainer} />
          <Route exact path="/teams" component={TeamsOptInPageContainer} />
          {/*<Route exact path="/master-coach" component={MasterCoachCertificationPageContainer} />*/}
          {/*<Route exact path="/mastercoach" component={MasterCoachCertificationPageContainer} />*/}
          {/*<Route exact path="/master_coach" component={MasterCoachCertificationPageContainer} />*/}
          {/*<Route exact path="/master-coach-waitlist" component={MasterCoachWaitListPageContainer} />*/}
          <Route exact path="/agents-waitlist" component={CoachAgentsWaitListPageContainer} />
          {/*<Route exact path="/master-coach-calendar" component={MasterCoachCalendarBookingPageContainer} />*/}
          {/*<Route exact path="/mastermind-calendar" component={MastermindCalendarBookingPageContainer} />*/}
          <Route exact path="/coach-os" component={PlatformPageContainer} />
          {/*<Route exact path="/coachos" render={() => <RedirectContainer redirectTo="/coach-os" />} />*/}
          {/*<Route exact path="/coach_os" render={() => <RedirectContainer redirectTo="/coach-os" />} />*/}
          {/*<Route exact path="/lolo" component={LoloPreLaunchVideoPageContainer} />*/}
          {/*<Route exact path="/course" component={CoursePageContainer} />*/}
          {/*<Route exact path="/nplus" component={NPlusPageContainer} />*/}
          {/*<Route exact path="/performance-coaching" component={NPlusPageContainer} />*/}
          {/*<Route exact path="/references" component={ResearchPageContainer} />*/}
          {/*<Route exact path="/community" component={CommunityPageContainer} />*/}
          <Route exact path="/nervous-system-glossary" component={LearnSitemapPageContainer} />
          <Route exact path="/nervous-system-glossary/:languageCode" component={LearnLocalizedSitemapPageContainer} />
          <Route exact path="/support" component={SupportPageContainer} />
          <Route exact path="/signup" component={ForceUpgradeAppPage} />
          <Route exact path="/login" component={ForceUpgradeAppPage} />
          <Route exact path="/reset_password" component={ForceUpgradeAppPage} />
          <Route path="/reset_password/:resetPasswordToken" component={ForceUpgradeAppPage} />
          <Route path="/coach/:coachProfileUsername" component={CoachProfilePageContainer} />
          <Route exact path="/app" component={ForceUpgradeAppPage} />
          <Route exact path="/app-checkout" component={WebAppCheckoutPageContainer} />
          <Route exact path="/quiz" component={QuizAppContainer} />
          <Route path="/quiz/r/:quizResultKey" component={QuizAppContainer} />
          <Route exact path="/purchase_confirmation/:productId" component={PurchaseConfirmationPageContainer} />
          <Route exact path="/android-account-deletion" component={AndroidAccountDeletionPageContainer} />
          <Route render={() => <RedirectContainer redirectTo="/" />} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps)(WebsiteApp);
