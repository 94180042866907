import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
  isAndroidDevice,
  isIOSDevice,
  preloadImagePaths,
  TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH,
  TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH,
  getCertOptInNameFromQueryString,
  getCertOptInEmailAddressFromQueryString,
  getCertOptInIsPrefilledFromQueryString
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import PieChartInsightSection from './PieChartInsightSection'
import AppReviewPanel from './AppReviewPanel'
import LineGraph from './LineGraph'
import MembershipFeatureDescription from './MembershipFeatureDescription'
import SendRequestButton from './SendRequestButton'

import agent from '../../agent'

import {
  NeuroFitNamedLogoSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_CLICKED_REGISTER_FOR_CERTIFICATION_TRAINING,
  GA_LABEL_CERTIFICATION_OPT_IN_PAGE_SCROLLED_DOWN,
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
} from '../../constants/requestStates'

// import {

// } from '../../constants/experimentFlags'

import TopTrainerTestimonials from './TopTrainerTestimonials'


const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class TrainerCertificationVideoDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: false,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      firstName: getCertOptInNameFromQueryString(),
      emailAddress: getCertOptInEmailAddressFromQueryString(),
      isFormPrefilled: getCertOptInIsPrefilledFromQueryString(),
      isAndroid: isAndroidDevice(),
      isIOS: isIOSDevice()
    }
  }

  handleOnClickLearnMore = (event) => {
    //.current is verification that your element has rendered
    if (this.learnMoreRef.current){
        this.learnMoreRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  handleOnScrollToCheckout = () => {
    if (this.checkoutRef.current) {
        this.checkoutRef.scrollIntoView({ 
           behavior: "smooth", 
           block: "nearest"
        })
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const {hasScrolled} = this.state

    if (!hasScrolled) {
      recordFunnelEventGA(GA_LABEL_CERTIFICATION_OPT_IN_PAGE_SCROLLED_DOWN)
    }

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const logoOutlineColor = `rgba(${237 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${238 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, ${242 * (1 - topPageScrollProgress) + 255 * topPageScrollProgress}, 1.0)`
      const heroBackdropFilter = `blur(${Math.min(topPageScrollProgress * 4, 4)}px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, hasScrolled: true, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, logoOutlineColor, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#EDEEF2", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    await initializePixelIfAvailable(true/*initTikTok*/)

    const innerWidth = document.body.clientWidth || window.innerWidth
    const isMobile = innerWidth <= MinDesktopWidthPixels

    await preloadImagePaths([isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH])
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isNasmPage,
      signUpForTrainingRequestResult,
      onSignUpForTraining,
      experimentFlags,
      attemptedDuplicateOptIn
    } = this.props


    const {show, firstName, emailAddress, isFormPrefilled, hasScrolled, isAndroid, isIOS, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const signUpInProgress = signUpForTrainingRequestResult.state === REQUEST_FETCHING

    const registerButtonDisabled = (!firstName) || (!emailAddress) || signUpInProgress

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const emphasizeFormFieldPlaceholderText = isAndroid
    const showLimitedTimeHeroText = isIOS
    const showPressAtBottomAboveFold = isAndroid
    const showWellAndGoodLaurel = isAndroid

    const heroText = `Become An Expert Nervous System Fitness™ Coach`
    const secondPageHeroText = `Get Superior Client Results With Nervous System Fitness™`
    const heroCtaText = (showLimitedTimeHeroText ? `Take 50% off the NEUROFIT Certification: register to unlock this limited-time offer now.` : `Take 50% off the NEUROFIT guided certification program: register to unlock now.`)

    return (
      <div 
        id={"certification-page-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={containerStyle}
      >
        <div style={{textAlign: "start", width: "100vw", minHeight: innerHeight, backgroundColor: "#EDEEF2"}}>
          <div style={{display: "flex", minHeight: innerHeight, height: "100%", width: "100%", flexDirection: "column", justifyContent: "space-between"}} ref={elem => this.signUpRef = elem}>
            <div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", paddingTop: 40, pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div style={{fontFamily: "Futura", fontSize: "min(8vw, 40px)", textTransform: "uppercase", textAlign: "start"}}>
                    <div>{"Become A Certified Nervous System + Somatics Coach"}</div>
                  </div>
                </div>
              </div>
            </div>
            {!showPressAtBottomAboveFold && (
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
                <div style={{width: "min(85vw, 400px)", height: "calc(min(85vw, 400px) / 8.089)", textAlign: "center", pointerEvents: "none", opacity: 0.5}}>
                  <img src={isMobile ? "/images/media-logos-4-mobile.webp" : "/images/media-logos-4-desktop.webp"} style={{width: "100%", opacity: isHeroImageLoaded ? 1 : 0, transform: isHeroImageLoaded ? "none" : "scale(0.925)", transition: "all 300ms ease-in", transitionProperty: "opacity, transform"}} onLoad={() => this.setState({isHeroImageLoaded: true})} alt="Media outlet features." />
                </div>
              </div>
            )}
            <div>
              <div style={{color: "black", width: "100%", marginTop: !showPressAtBottomAboveFold ? 0 : "2vh", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
                <div style={{width: "85%", maxWidth: 600}}>
                  <div style={{display: "inline-block", fontSize: "min(6vw, 26px)", textShadow, textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em"}}>
                    <span>{heroCtaText}</span>
                  </div>
                </div>
              </div>
              <div>
                <div style={{color: "black", width: "100%", display: "flex", marginTop: `3vh`, justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 600}}>
                    <div>
                      <input
                        type="text"
                        value={firstName}
                        disabled={signUpInProgress || isFormPrefilled}
                        autoCapitalize="true"
                        autoCorrect="false"
                        autoComplete="given-name"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({firstName: e.target.value})
                        }}
                        onPaste={(e) => {
                          this.setState({firstName: e.target.value})
                        }}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter' && (!registerButtonDisabled)) {
                            onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                          }
                        }}
                        placeholder={"First Name"}
                        className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                      />
                    </div>
                    <div style={{margin: "min(2vh, 10px) 0px"}}>
                      <input
                        type="text"
                        value={emailAddress}
                        disabled={signUpInProgress || isFormPrefilled}
                        autoCapitalize="none"
                        autoCorrect="false"
                        autoComplete="email"
                        spellCheck={false}
                        onChange={(e) => {
                          this.setState({emailAddress: e.target.value.toLowerCase()})
                        }}
                        onPaste={(e) => {
                          this.setState({emailAddress: e.target.value.toLowerCase()})
                        }}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter' && (!registerButtonDisabled)) {
                            onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                          }
                        }}
                        placeholder={"Email Address"}
                        className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                      />
                    </div>
                    <SendRequestButton
                      defaultContent={"UNLOCK 50% OFF"}
                      backgroundColor={"#000000"}
                      loadingText={"UNLOCKING OFFER..."}
                      successText={"OFFER UNLOCKED"}
                      requestState={signUpForTrainingRequestResult.state}
                      isDisabled={registerButtonDisabled}
                      onClick={() => {
                        onSignUpForTraining({first_name: firstName, email_address: emailAddress})
                      }}
                    />
                    <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "min(2vh, 10px)"}}>
                      <span>{(showLimitedTimeHeroText ? "" : "Limited-time offer. ") + "By submitting this form, you agree to our "}</span>
                      <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                      <span style={{marginInlineEnd: "0.2em"}}>{" and "}</span>
                      <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                      <span>{". Thank you!"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showPressAtBottomAboveFold && (
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
                <div style={{width: "min(85vw, 400px)", height: "calc(min(85vw, 400px) / 8.089)", textAlign: "center", pointerEvents: "none", opacity: 0.5}}>
                  <img src={isMobile ? "/images/media-logos-4-mobile.webp" : "/images/media-logos-4-desktop.webp"} style={{width: "100%", opacity: isHeroImageLoaded ? 1 : 0, transform: isHeroImageLoaded ? "none" : "scale(0.925)", transition: "all 300ms ease-in", transitionProperty: "opacity, transform"}} onLoad={() => this.setState({isHeroImageLoaded: true})} alt="Media outlet features." />
                </div>
              </div>
            )}
            <div style={{height: "3vh"}}>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TrainerCertificationVideoDetails