import React from 'react'

import {
  ProductionBaseUrl,
  DefaultAspectRatio,
  recordFunnelEventGA,
  recordFunnelEventResultGA,
  initializePixelIfAvailable,
  recordPixelEventIfAvailable,
  NeuroFitUniversalAppStoreLink,
  MinDesktopWidthPixels,
  subdomainUrls,
  isAndroidDevice,
  isIOSDevice,
  preloadImagePaths,
  NeuroFitWorkplaceBurnoutRecoveryPlanLink,
  TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH,
  TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH,
  getCertOptInNameFromQueryString,
  getCertOptInEmailAddressFromQueryString,
  getCertOptInIsPrefilledFromQueryString,
  recordLinkClickAndRedirect,
  getCurrentYear,
  getPixelParams,
  getCampaignCodeFromQueryString,
  delay,
} from '../../utils'

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import { range } from 'lodash-es';
import StarRatings from 'react-star-ratings'

import LoadingLogo from './LoadingLogo'
import AutoPlayInlineVideoContainer from './AutoPlayInlineVideoContainer'
import PieChartInsightSection from './PieChartInsightSection'
import AppReviewPanel from './AppReviewPanel'
import LineGraph from './LineGraph'
import MembershipFeatureDescription from './MembershipFeatureDescription'
import SendRequestButton from './SendRequestButton'
import ConfirmSection from './ConfirmSection'
import DownloadButtonsSection from './DownloadButtonsSection'
import InlineTrainerTestimonial from './InlineTrainerTestimonial'
import TeamsOptInSuccessModal from '../modals/TeamsOptInSuccessModal'


import agent from '../../agent'

import {
  NeuroFitNamedLogoSvg,
  VerifiedSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_TEAMS_CLICKED_REGISTER_FOR_TEAMS,
  GA_LABEL_TEAMS_CLICKED_REPORT
} from '../../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import loadable from '@loadable/component'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


class TeamOptInFormDetails extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showSettingsModal: false,
      hasScrolled: false,
      signUpRef: React.createRef(),
      learnMoreRef: React.createRef(),
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#FFFFFF",
      show: true,
      showIframe: false,
      isHeroImageLoaded: false,
      videoOpacity: 1.0,
      currentHeaderIndex: -1,
      storyLoadingLength: 3500,
      videoDownloadProgress: 0,
      manualStartRef: React.createRef(),
      firstName: "",
      lastName: "",
      emailAddress: "",
      organizationName: "",
      professionalTitle: "",
      teamSize: "",
      optionCertificationCourse: false,
      optionExecutiveCoaching: false,
      optionTeamAppAccess: false,
      optionLiveTeamWorkshops: false,
      isAndroid: isAndroidDevice(),
      isIOS: isIOSDevice(),
      optInResultState: REQUEST_UNSTARTED,
      showOptInSuccessModal: false
    }
  }

  updateComponentsOnContainerScroll = e => {
    const {innerHeight} = this.props

    const {hasScrolled} = this.state

    const scrollTop = e.target.scrollTop

    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight), 0.0), 1.0)
      const headerBackgroundColor = `rgba(255, 255, 255, ${Math.min(topPageScrollProgress, 0.85)})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0 + 0.05})`
      const headerBorderColor = `rgba(0, 0, 0, ${topPageScrollProgress})`
      const headerBackgroundBlur = `blur(4px)`
      const videoOpacity = scrollTop <= (innerHeight * 1.2) ? 1.0 : 0.0
      const heroBackdropFilter = `blur(4px)`
      const heroOverlayColor = `rgba(237, 238, 242, ${topPageScrollProgress})`

      const updatedState = {headerBackgroundColor, hasScrolled: true, heroOverlayColor, dropShadowColor, heroBackdropFilter, topPageScrollProgress, headerBackgroundBlur, headerBorderColor/* `rgba(53, 63, 106, ${alpha}`*/, videoOpacity}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    } else {
      const updatedState = {headerBackgroundColor: "transparent", hasScrolled: true, heroOverlayColor: "transparent", heroBackdropFilter: "none", dropShadowColor: "transparent", logoOutlineColor: "#FFFFFF", headerBackgroundBlur: "none", topPageScrollProgress: 0, headerBorderColor: "transparent", videoOpacity: 1}
      this.setState(updatedState)
      if (this.props.onContainerScroll) {
        this.props.onContainerScroll(updatedState)
      }
    }
  }

  async componentDidMount() {
    const {isNative} = this.props

    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateComponentsOnContainerScroll)

    await initializePixelIfAvailable(!isNative/*initTikTok*/)

    const innerWidth = document.body.clientWidth || window.innerWidth
    const isMobile = innerWidth <= MinDesktopWidthPixels

    await preloadImagePaths([isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH])
  }

  async hapticIfNative() {
    const {isNative} = this.props
    if (isNative) {
      await Haptics.impact({ style: ImpactStyle.Light })
    }
  }

  async onSignUpForTraining() {

    const {firstName, lastName, emailAddress, organizationName, professionalTitle, teamSize, optionTeamAppAccess, optionCertificationCourse, optionLiveTeamWorkshops, optionExecutiveCoaching} = this.state

    const {isNative} = this.props

    recordFunnelEventResultGA(
      GA_LABEL_TEAMS_CLICKED_REGISTER_FOR_TEAMS,
      {
        organization_name: organizationName,
        professional_title: professionalTitle,
        team_size: teamSize,
        option_team_app_access: optionTeamAppAccess,
        option_certification_course: optionCertificationCourse,
        option_team_workshops: optionLiveTeamWorkshops,
        option_executive_coaching: optionExecutiveCoaching,
      }
    )

    const requestBody = {
      first_name: firstName,
      last_name: lastName,
      email_address: emailAddress,
      organization_name: organizationName,
      professional_title: professionalTitle,
      team_size: teamSize,
      option_team_app_access: optionTeamAppAccess,
      option_certification_course: optionCertificationCourse,
      option_team_workshops: optionLiveTeamWorkshops,
      option_executive_coaching: optionExecutiveCoaching,
    }

    this.setState({optInResultState: REQUEST_FETCHING})
    await this.hapticIfNative()
    try {
      const trainingRequestResult = await agent.NeuroFitBackend.submitTeamsOptInForm(requestBody)
      this.setState({optInResultState: REQUEST_SUCCESS})
      if (isNative) {
        await Haptics.notification({ type: NotificationType.Success })
      }
      await delay(500)
      this.setState({showOptInSuccessModal: true})

    } catch (err) {
      this.setState({optInResultState: REQUEST_ERROR})
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      isNative,
      isModal,
      onClickCertificationButton,
    } = this.props

    const {show, firstName, lastName, emailAddress, organizationName, professionalTitle, teamSize, optionTeamAppAccess, optionCertificationCourse, optionLiveTeamWorkshops, optionExecutiveCoaching, optInResultState, showOptInSuccessModal, hasScrolled, isAndroid, isIOS, learnMoreRef, showSettingsModal, scrollingContainerRef, headerBackgroundColor, videoDownloadProgress, heroOverlayColor, heroBackdropFilter, headerBorderColor, headerBackgroundBlur, logoOutlineColor, dropShadowColor, topPageScrollProgress, showIframe, isHeroImageLoaded, currentHeaderIndex, storyLoadingLength, videoOpacity} = this.state

    const innerWidth = document.body.clientWidth || window.innerWidth

    const videoWidthPixels = Math.max(innerHeight / DefaultAspectRatio, innerWidth)

    const textShadow = range(4).map(() => (`0px 0px 3px rgba(237, 238, 242, 0.5)`)).join(",")
    const linkButtonTextShadow = range(4).map(() => (`0px 0px 3px ${logoOutlineColor}`)).join(",")
    const svgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px ${logoOutlineColor})`)).join(" ")
    const coachTextShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")

    const signUpInProgress = optInResultState === REQUEST_FETCHING

    const registerButtonDisabled = (!firstName) || (!lastName) || (!emailAddress) || (!organizationName) || (!teamSize) || signUpInProgress

    const isMobile = innerWidth <= MinDesktopWidthPixels

    const emphasizeFormFieldPlaceholderText = isAndroid
    const showLimitedTimeHeroText = isIOS
    const showPressAtBottomAboveFold = isAndroid
    const showWellAndGoodLaurel = isAndroid

    const currentYear = getCurrentYear()

    const teamSizeOptions = [
      {value: "", label: "Select Team Size"},
      {value: "1-20", label: "1-20"},
      {value: "20-50", label: "20-50"},
      {value: "50-100", label: "50-100"},
      {value: "100-500", label: "100-500"},
      {value: "500+", label: "500+"}
    ]

    return (
      <div 
        id={"teams-opt-in-container"}
        className={"disable-scrollbars"}
        ref={elem => this.scrollingContainerRef = elem}
        style={{...containerStyle, height: isModal ? undefined : "100%"}}
      >
        <div style={{textAlign: "start", width: "100vw", minHeight: `calc(${innerHeight}px - 120px - 10vh)`, backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid", padding: `calc(60px + 5vh) 0px`}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <h1 style={{fontSize: "min(8vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                {"Solve Team Burnout In Just 6 Weeks"}
              </h1>
              <h2 style={{fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Book", marginTop: "2vh", marginInlineEnd: "0.3em"}}>
                {"Help your organization feel + perform their best, with a methodology + tech trusted by "}
                <span style={{fontFamily: "Futura"}}>{"2,000+ health professionals."}</span>
              </h2>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", pointerEvents: "none", margin: "6vh 0px"}}>
                <div style={{width: "min(85vw, 400px)", height: "calc(min(85vw, 400px) / 8.089)", marginTop: "2vh", textAlign: "center", pointerEvents: "none", opacity: 0.5}}>
                  <img src={isMobile ? "/images/media-logos-4-mobile.webp" : "/images/media-logos-4-desktop.webp"} style={{width: "100%", opacity: isHeroImageLoaded ? 1 : 0, transform: isHeroImageLoaded ? "none" : "scale(0.925)", transition: "all 300ms ease-in", transitionProperty: "opacity, transform"}} onLoad={() => this.setState({isHeroImageLoaded: true})} alt="Media outlet features." />
                </div>
              </div>
              <div style={{marginTop: "4vh", fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Book"}}>
                <div style={{marginTop: "4vh"}}>
                  {`Download our free `}
                  <a
                    style={{fontFamily: "Futura Medium", color: "#000000", textDecoration: "none", border: "none"}}
                    href={NeuroFitWorkplaceBurnoutRecoveryPlanLink}
                    target={"_blank"}
                    onClick={() => {
                      this.hapticIfNative()
                      recordFunnelEventGA(GA_LABEL_TEAMS_CLICKED_REPORT)
                      
                    }}
                  > 
                    {`${currentYear} Burnout Recovery Plan`}
                  </a>
                  {` for organizations to get started:`}
                </div>
              </div>
              <div style={{display: "flex", justifyContent: "center", marginTop: "4vh"}}>
                <a
                  style={{display: "flex", alignItems: "top", justifyContent: "center", textTransform: "uppercase", textDecoration: "none", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                  href={NeuroFitWorkplaceBurnoutRecoveryPlanLink}
                  target={"_blank"}
                  onClick={() => {
                    this.hapticIfNative()
                    recordFunnelEventGA(GA_LABEL_TEAMS_CLICKED_REPORT)
                    
                  }}
                > 
                  {`Burnout Recovery Plan`}
                </a>
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{display: "flex", justifyContent: "center", marginTop: "3vh", pointerEvents: "none"}}>
                  <svg onClick={() => this.fourTruthsSectionRef.scrollIntoView({behavior: "smooth"})} style={{pointerEvents: "all", cursor: "pointer", height: "min(12vw, 70px)", width: "min(12vw, 70px)"}} className="loading-pulse-animation-down-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1" x="0px" y="0px"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g fill="#000000"><path d="M20,27.4142136 L32.7071068,14.7071068 L31.2928932,13.2928932 L20,24.5799999 L8.70710678,13.2928932 L7.29289322,14.7071068 L20,27.4142136 Z"></path></g></g></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", backgroundColor: "#EDEEF2", borderTop: "2px #000000 solid", padding: `calc(60px + 5vh) 0px`}} ref={elem => this.fourTruthsSectionRef = elem}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                {"The 4 Truths Of Organization Burnout"}
              </div>
              <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", marginTop: "6vh", fontFamily: "Futura"}}>
                {"#1: Team burnout is the result of chronic stress."}
              </div>
              <div style={{marginTop: "1vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                <div>
                  {"Chronic stress builds up in the body, changing our physiology and creating physical + mental health issues that we now call \"burnout\"."}
                </div>
                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                  <div style={{width: "100%", maxWidth: 450}}>
                    <img src={`/assets/images/learn/en/chronic-stress-buildup-allostatic-load.webp?v=1`} fetchpriority="high" style={{width: "100%", height: "calc(min(85vw, 450px) * 253 / 332)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", zIndex: 1, margin: "3vh 0px"}} />
                  </div>
                </div>
              </div>
              <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", marginTop: "6vh", fontFamily: "Futura"}}>
                {"#2: Burnout lowers team resilience + performance."}
              </div>
              <div style={{marginTop: "1vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                <div>
                  {"When teams experience high stress loads, they become less resilient and imbalanced, and performance suffers as a result."}
                </div>
                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                  <div style={{width: "100%", maxWidth: 450}}>
                    <img src={`/assets/images/learn/en/window-of-tolerance-nervous-system.webp?v=1`} fetchpriority="high" style={{width: "100%", height: "calc(min(85vw, 450px) * 1911 / 1000)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", zIndex: 1, margin: "3vh 0px"}} />
                  </div>
                </div>
              </div>
              <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", marginTop: "6vh", fontFamily: "Futura"}}>
                {"#3: Burnout is resolved in the nervous system."}
              </div>
              <div style={{marginTop: "1vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                <div>
                  {"Unfortunately, solutions like talk therapy or mindset coaching don't work for chronic stress + burnout. They're not designed to address the body-based problem of burnout - but NEUROFIT is:"}
                </div>
                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                  <div style={{width: "100%", maxWidth: 450}}>
                    <PieChartInsightSection
                      title={`EMBODIMENT VS. MINDSET`}
                      marginTop={"2vh"}
                      disableDropDown
                      dataIsAvailable
                      data={{"embodiment": 80, "mindset": 20}}
                      insightText={`The NEUROFIT Method is body-based, harnessing 80% of the nervous system - mindset coaching only speaks to 20%.`}
                      dataCategories={[{title_name: "Embodiment", name: "embodiment", color: "#FFD634"}, {title_name: "Mindset", name: "mindset", color: "#9EA0DA"}]}
                    />
                  </div>
                </div>
              </div>
              <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", marginTop: "6vh", fontFamily: "Futura"}}>
                {"#4: Every team member needs a unique burnout solution."}
              </div>
              <div style={{fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                <div style={{marginTop: "1vh"}}>
                  {"When burnt out, some people experience high-energy stress, while others shift to low-energy stress or overwhelm. The solution is as unique as the problem."}
                </div>
                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                  <div style={{width: "100%", maxWidth: 450}}>
                    <img src={`/assets/images/learn/en/neurofit-ring-nervous-system-states.webp?v=1`} fetchpriority="high" style={{width: "100%", height: "calc(min(85vw, 450px) * 860 / 450)", boxShadow: "0px 1px 1.5px 0.5px #dddddd", zIndex: 1, margin: "3vh 0px"}} />
                  </div>
                </div>
              </div>
              <div style={{marginTop: "3vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                <span>{"This is where our products excel - reducing burnout up to "}</span>
                <span style={{fontFamily: "Futura"}}>{`7X faster`}</span>
                <span>{" by focusing on the nervous system:"}</span>
              </div>
              <div style={{display: "flex", width: "100%", marginTop: "2vh", justifyContent: "center"}}>
                <div style={{width: "100%", maxWidth: 450}}>
                  <LineGraph
                    dataIsAvailable
                    title={"Daily Reported Stress"}
                    data={{
                      labels: ["0", "1", "2", "3", "4", "5", "6", "7"],
                      series: [[64.7, 41.2, 34.5, 34.2, 34.6, 35.7, 29.9, 29.9].map(x => x * 100.0 / 64.7)],
                    }}
                    labelInterpolationFnc={(val) => `${val}%`}
                    highYValue={100}
                    hideLogo
                    xAxisTitle={"Days on NEUROFIT"}
                  />
                </div>
              </div>
              <div style={{marginTop: "6vh", marginBottom: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                <span>{`The NEUROFIT Method is validated by.`}</span>
                <span style={{fontFamily: "Futura"}}>{`7M+ in-app datapoints`}</span>
                <span>{`, and grounded in leading neuroscience research.`}</span>
              </div>
              <div style={{marginBottom: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                {`To learn more, download our free Burnout Recovery Plan:`}
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <a
                  style={{display: "flex", alignItems: "top", justifyContent: "center", textTransform: "uppercase", textDecoration: "none", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                  href={NeuroFitWorkplaceBurnoutRecoveryPlanLink}
                  target={"_blank"}
                  onClick={() => {
                    this.hapticIfNative()
                    recordFunnelEventGA(GA_LABEL_TEAMS_CLICKED_REPORT)
                    
                  }}
                > 
                  {`Get ${currentYear} Burnout Plan`}
                </a>
              </div>
            </div>
          </div>
        </div>
        {!isModal && !isNative && (
          <div style={{textAlign: "start", width: "100vw", padding: "calc(60px + 5vh) 0px", backgroundColor: "#FFFFFF", borderTop: "2px #000000 solid"}} ref={elem => this.learnMoreRef = elem}>
            <div style={{height: "100%"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                    {"Backed By Data."}
                  </div>
                  <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                    {"Trusted By 2,000+ Health Experts."}
                  </div>
                  <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <img src={`/images/hero-phone-balance-${isMobile ? "mobile" : "desktop"}.webp`} fetchpriority="high" style={{height: "50vh", zIndex: 1, margin: "3vh 0px"}} />
                    <img src={`/images/hero-phone-insights-${isMobile ? "mobile" : "desktop"}.webp`} fetchpriority="high" style={{height: "calc(50vh * 0.896)", zIndex: 0, margin: "calc(3vh + calc(50vh * 0.052)) 0px 3vh 0px"}} />
                  </div>
                  <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <span>{`NEUROFIT is designed to improve well-being fast: on average, active users report `}</span>
                    <span style={{fontFamily: "Futura"}}>{`54% less stress after 1 week.`}</span>
                  </div>
                  <div style={{marginTop: "2vh", fontSize: "min(5vw, 25px)", textAlign: "start", fontFamily: "Futura Light"}}>
                    <span style={{fontFamily: "Futura"}}>{`Hundreds of therapists, doctors, health coaches, and more`}</span>
                    <span>{` use our nervous system method with clients.`}</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div style={{display: "flex", justifyContent: "center", marginTop: "5vh", pointerEvents: "none"}}>
                    <img src={isMobile ? "/images/media-logos-4-mobile.webp?v=2" : "/images/media-logos-4-desktop.webp?v=2"} fetchpriority="high" style={{width: "85%", maxWidth: 500, height: "calc(min(500px, 85%) / 8.089)", opacity: 0.5}} alt="NEUROFIT has been featured in SHAPE, Well and Good, and Body and Soul." />
                  </div>
                  <div style={{textAlign: "center", margin: "5vh auto 2vh auto", opacity: 1.0}}>
                    <div>
                      <StarRatings
                        disabled
                        rating={4.8}
                        starRatedColor={"#000000"}
                        starEmptyColor={"#CFD2DD"}
                        starDimension={"min(8vw, 40px)"}
                        starSpacing={"min(2vw, 10px)"}
                        numberOfStars={5}
                      />
                    </div>
                    <div style={{fontSize: "min(5vw, 26px)", textAlign: "center", fontFamily: "Futura Medium", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                      {"4.8/5 App Store Rating"}
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                      <VerifiedSvg
                        color={"#a5a8b0"}
                        size={"min(4.5vw, 18px)"}
                      />
                      <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#a5a8b0", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                        {"60,000+ Users"}
                      </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10}}>
                      <div style={{fontSize: "min(4.5vw, 18px)", lineHeight: "min(4.5vw, 18px)", color: "#a5a8b0", textAlign: "center", fontFamily: "Futura Medium", marginInlineStart: "0.3em", textTransform: "uppercase"}}>
                        {"IOS + ANDROID"}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{color: "black", width: "100%",display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <DownloadButtonsSection
                      isIOSDevice={isIOSDevice}
                      isAndroidDevice={isAndroidDevice}
                      onShowQrCodeModal={() => this.setState({showQrCodeModal: true})}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{textAlign: "start", width: "100vw", padding: "6vh 0vh", backgroundColor: "#000000"}}>
          <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-around"}}>
            <img fetchpriority="high" src="/images/shape-groundbreaking-recovery-innovations-white.webp?v=3" style={{width: "min(calc(75% * 0.908), calc(400px * 0.908)", height: "calc(min(calc(75% * 0.908), calc(400px * 0.908) * 152 / 501)"}} style={{width: "min(calc(75% * 0.908), calc(400px * 0.908)"}} alt="NEUROFIT is a 2023 SHAPE Magazine Groundbreaking Recovery Innovation." />
          </div>
          <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
            <img fetchpriority="high" src="/images/well-and-good-laurel-white.webp?v=2" style={{width: "min(75%, 400px)", height: "calc(min(75%, 400px) * 120 / 501)"}} alt="NEUROFIT eased my stress in ways meditation never has -Well and Good" />
          </div>
          <div style={{display: "flex", width: "100%", marginTop: "3vh", alignItems: "center", justifyContent: "space-around"}}>
            <img fetchpriority="high" src="/images/nasm-afaa-black-and-white.webp?v=2" style={{width: "min(80%, 500px)", height: "calc(min(80%, 500px) * 252 / 897)"}} alt="NEUROFIT is a NASM + AFAA Approved Provider." />
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", backgroundColor: "#FFFFFF", padding: `calc(60px + 5vh) 0px`, borderTop: "2px #000000 solid"}}>
          <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 700}}>
              <div style={{fontFamily: "Futura", textTransform: "uppercase", fontSize: "min(8vw, 40px)", textAlign: "start", whitepace: "pre-wrap"}}>
                <div>{isMobile ? "Get Certified In Chronic Stress + Burnout Relief" : "Get Certified In Chronic Stress + Burnout Relief"}</div>
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: "4vh 0px"}}>
                <img src={isMobile ? TRAINER_CERTIFICATION_MOBILE_HERO_IMAGE_PATH : TRAINER_CERTIFICATION_DESKTOP_HERO_IMAGE_PATH} loading="lazy" style={{width: "100%", maxWidth: 600}} alt="A 3-Week Nervous System Certification for practitioners, trainers, gym owners, coaches & therapists looking to accelerate personal & professional growth." />
              </div>
              <div style={{marginTop: "max(3vh, 20px)", fontFamily: "Futura Book", paddingBottom: "1vh", fontSize: "min(6vw, 26px)", textAlign: "start"}}>
                <span>{`Learn how to reduce + prevent burnout with our beginner-friendly online certification course - taken by `}</span>
                <span style={{fontFamily: "Futura"}}>{` 500+ leading health professionals`}</span>
                <span>{`.`}</span>
              </div>
              <div style={{display: "flex", justifyContent: "center"}}>
                <button
                  style={{display: "flex", pointerEvents: "all", alignItems: "top", justifyContent: "center", width: "100%", maxWidth: 400, textAlign: "center", fontFamily: "Futura", fontSize: "min(6vw, 25px)", lineHeight: "min(6vw, 25px)", marginTop: "4vh", color: "#ffffff", backgroundColor: "#000000", padding: "18px 15px", border: "none"}}
                  onClick={() => {
                    onClickCertificationButton()
                  }}
                > 
                  {"VIEW CERTIFICATION"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{textAlign: "start", width: "100vw", backgroundColor: "#000000", borderTop: "2px #000000 solid", borderBottom: "2px #000000 solid"}}>
          <div>
            <div style={{color: "black", width: "100%", display: "flex", color: "#FFFFFF", padding: "calc(5vh + 60px) 0px", justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 700}}>
                <div style={{display: "inline-block", fontSize: "min(8.5vw, 40px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", textTransform: "uppercase"}}>
                  {"Trusted + Used By Leading Health Professionals"}
                </div>
                <div style={{display: "flex", marginTop: "3vh", width: "100%", justifyContent: "center"}}>
                  <div style={{maxWidth: "min(100%, 600px)"}}>
                    <MembershipFeatureDescription
                      fontSize={"min(6vw, 26px)"}
                      fontFamily={"Futura"}
                      checkMarginTop={"0.35em"}
                      marginTop={"6vh"}
                      checkboxBaseClassName={"cert-page-plan-button-white"}
                      textColor={"#FFFFFF"}
                      checkColorComplete={"#000000"}
                      text={"UNDERSTAND BURNOUT"}
                    />
                    <InlineTrainerTestimonial
                      name={"Ashley"}
                      occupation={"Fitness Trainer"}
                      profileImageSrc={"/images/trainers/ashley.jpeg"}
                      countryFlag={"🇺🇸"}
                      marginTop={"2vh"}
                      backgroundColor={"#2A2A2A"}
                      titleColor={"#FFFFFF"}
                      testimonialText={"Completing the NEUROFIT certification alongside using the app has been a profoundly transformative experience for me. Of all the certifications and continuing education I’ve done, this one was not only the most impactful, but also delivered in the simplest way."}
                    />
                    <MembershipFeatureDescription
                      fontSize={"min(6vw, 26px)"}
                      fontFamily={"Futura"}
                      checkMarginTop={"0.35em"}
                      marginTop={"6vh"}
                      checkboxBaseClassName={"cert-page-plan-button-white"}
                      textColor={"#FFFFFF"}
                      checkColorComplete={"#000000"}
                      text={"IMPROVE TEAM CLARITY"}
                    />
                    <InlineTrainerTestimonial
                      name={"Olivia"}
                      occupation={"Physician + TedX Speaker"}
                      countryFlag={"🇦🇺"}
                      marginTop={"2vh"}
                      backgroundColor={"#2A2A2A"}
                      titleColor={"#FFFFFF"}
                      profileImageSrc={"/images/trainers/olivia.jpeg"}
                      testimonialText={"NEUROFIT has helped me recalibrate and reset my nervous system, making me feel calmer and more confident, and improving my performance as a physician and high-performance leadership coach."}
                    />
                    <MembershipFeatureDescription
                      fontSize={"min(6vw, 26px)"}
                      fontFamily={"Futura"}
                      checkMarginTop={"0.35em"}
                      marginTop={"6vh"}
                      checkboxBaseClassName={"cert-page-plan-button-white"}
                      textColor={"#FFFFFF"}
                      checkColorComplete={"#000000"}
                      text={"OPTIMIZE PERFORMANCE"}
                    />
                    <InlineTrainerTestimonial
                      name={"Jon"}
                      occupation={"Performance Specialist"}
                      profileImageSrc={"/images/trainers/jon.jpeg"}
                      countryFlag={"🇺🇸"}
                      marginTop={"2vh"}
                      backgroundColor={"#2A2A2A"}
                      titleColor={"#FFFFFF"}
                      testimonialText={"This work has the potential to transform lives... and I don't use that phrase lightly. Seeing progress within the app keeps you motivated -- you'll notice an impact within a matter of days."}
                    />
                    <MembershipFeatureDescription
                      fontSize={"min(6vw, 26px)"}
                      fontFamily={"Futura"}
                      checkMarginTop={"0.35em"}
                      marginTop={"6vh"}
                      checkboxBaseClassName={"cert-page-plan-button-white"}
                      textColor={"#FFFFFF"}
                      checkColorComplete={"#000000"}
                      text={"GET RESULTS THAT LAST"}
                    />
                    <InlineTrainerTestimonial
                      name={"Kerry"}
                      occupation={"Licensed Therapist"}
                      profileImageSrc={"/images/trainers/kerry.jpeg"}
                      countryFlag={"🇺🇸"}
                      marginTop={"2vh"}
                      backgroundColor={"#2A2A2A"}
                      titleColor={"#FFFFFF"}
                      testimonialText={"NEUROFIT was the missing piece for my clients to truly understand themselves and learn tools that would give them permanent results. It taught me a new perspective on ROOT CAUSE instead of bandaid treatments. NEUROFIT has not only changed my life professionally, but as someone who has dealt with complex trauma, burnout and debilitating chronic pain, this was the first year of my life that I can truly say I'm anxiety free and I feel safe and energized in my own body."}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{width: "100%", backgroundColor: "#EDEEF2", padding: "calc(60px + 5vh) 0px"}}>
          <div style={{color: "black", backgroundColor: "#EDEEF2", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
            <div style={{width: "85%", maxWidth: 600}}>
              <div style={{fontFamily: "Futura", fontSize: "min(8vw, 40px)", textTransform: "uppercase", textAlign: "start"}}>
                <div>{"Solve Team Burnout Fast With NEUROFIT"}</div>
              </div>
            </div>
          </div>
          <div style={{minWidth: "100%"}}>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", pointerEvents: "none", margin: "3vh 0px"}}>
              <div style={{width: "min(85vw, 400px)", height: "calc(min(85vw, 400px) / 8.089)", marginTop: "2vh", textAlign: "center", pointerEvents: "none", opacity: 0.5}}>
                <img src={isMobile ? "/images/media-logos-4-mobile.webp" : "/images/media-logos-4-desktop.webp"} style={{width: "100%", opacity: isHeroImageLoaded ? 1 : 0, transform: isHeroImageLoaded ? "none" : "scale(0.925)", transition: "all 300ms ease-in", transitionProperty: "opacity, transform"}} onLoad={() => this.setState({isHeroImageLoaded: true})} alt="Media outlet features." />
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", pointerEvents: "none"}}>
              <div style={{width: "85%", maxWidth: 600}}>
                <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura", marginTop: "2vh", marginInlineEnd: "0.3em"}}>
                  <span>{"Request a demo by completing the form below."}</span>
                </div>
              </div>
            </div>
            <div style={{color: "black", width: "100%", display: "flex", marginTop: `3vh`, justifyContent: "center", alignItems: "top"}}>
              <div style={{width: "85%", maxWidth: 600}}>
                <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", marginBottom: "2vh", marginTop: "4vh"}}>
                  <span>{"I. CONTACT DETAILS"}</span>
                </div>
                <div>
                  <input
                    type="text"
                    value={firstName}
                    disabled={signUpInProgress}
                    autoCapitalize="true"
                    autoCorrect="false"
                    autoComplete="given-name"
                    spellCheck={false}
                    onChange={(e) => {
                      this.setState({firstName: e.target.value})
                    }}
                    onPaste={(e) => {
                      this.setState({firstName: e.target.value})
                    }}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter' && (!registerButtonDisabled)) {
                        this.onSignUpForTraining()
                      }
                    }}
                    placeholder={"First Name"}
                    className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                  />
                </div>
                <div style={{margin: "min(3vh, 15px) 0px"}}>
                  <input
                    type="text"
                    value={lastName}
                    disabled={signUpInProgress}
                    autoCapitalize="true"
                    autoCorrect="false"
                    autoComplete="family-name"
                    spellCheck={false}
                    onChange={(e) => {
                      this.setState({lastName: e.target.value})
                    }}
                    onPaste={(e) => {
                      this.setState({lastName: e.target.value})
                    }}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter' && (!registerButtonDisabled)) {
                        this.onSignUpForTraining()
                      }
                    }}
                    placeholder={"Last Name"}
                    className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                  />
                </div>
                <div style={{margin: "min(3vh, 15px) 0px"}}>
                  <input
                    type="text"
                    value={emailAddress}
                    disabled={signUpInProgress}
                    autoCapitalize="none"
                    autoCorrect="false"
                    autoComplete="email"
                    spellCheck={false}
                    onChange={(e) => {
                      this.setState({emailAddress: e.target.value.toLowerCase()})
                    }}
                    onPaste={(e) => {
                      this.setState({emailAddress: e.target.value.toLowerCase()})
                    }}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter' && (!registerButtonDisabled)) {
                        this.onSignUpForTraining()
                      }
                    }}
                    placeholder={"Work Email Address"}
                    className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                  />
                </div>
                <div style={{margin: "min(3vh, 15px) 0px"}}>
                  <input
                    type="text"
                    value={professionalTitle}
                    disabled={signUpInProgress}
                    autoCapitalize="true"
                    autoCorrect="false"
                    autoComplete="organization-title"
                    spellCheck={false}
                    onChange={(e) => {
                      this.setState({professionalTitle: e.target.value})
                    }}
                    onPaste={(e) => {
                      this.setState({professionalTitle: e.target.value})
                    }}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter' && (!registerButtonDisabled)) {
                        this.onSignUpForTraining()
                      }
                    }}
                    placeholder={"Professional Title"}
                    className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                  />
                </div>
                <div style={{margin: "min(3vh, 15px) 0px"}}>
                  <input
                    type="text"
                    value={organizationName}
                    disabled={signUpInProgress}
                    autoCapitalize="true"
                    autoCorrect="false"
                    autoComplete="organization"
                    spellCheck={false}
                    onChange={(e) => {
                      this.setState({organizationName: e.target.value})
                    }}
                    onPaste={(e) => {
                      this.setState({organizationName: e.target.value})
                    }}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter' && (!registerButtonDisabled)) {
                        this.onSignUpForTraining()
                      }
                    }}
                    placeholder={"Organization Name"}
                    className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                  />
                </div>
                <div style={{margin: "min(3vh, 15px) 0px"}}>
                  <select
                    id="team-size-select"
                    value={teamSize}
                    disabled={signUpInProgress}
                    autoCapitalize="true"
                    autoCorrect="false"
                    autoComplete="organization"
                    spellCheck={false}
                    onChange={(e) => {
                      console.log(e.target.value)
                      this.setState({teamSize: e.target.value})
                    }}
                    style={{color: !teamSize ? "#BABDC6" : "#000000", width: "100%", appearance: "none"}}
                    className={"application-input" + (emphasizeFormFieldPlaceholderText ? " application-input-emphasized" : "")}
                  >
                    {teamSizeOptions.map(opt => (
                      <option value={opt.value} selected={opt.value === teamSize} hidden={opt.value === ""}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{margin: "2vh 0px 4vh 0px"}}>
                  <div style={{fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura", marginInlineEnd: "0.3em", marginBottom: "2vh", marginTop: "4vh"}}>
                    <span>{"II. PRODUCT INTERESTS"}</span>
                  </div>
                  <ConfirmSection
                    label={"Team App Access"}
                    fontFamily={"Futura"}
                    isConfirmed={optionTeamAppAccess}
                    onToggleConfirm={(val) => {
                      this.setState({optionTeamAppAccess: val})
                      this.hapticIfNative()
                    }}
                    isDisabled={signUpInProgress}
                    marginTop={"2vh"}
                    color={"#000000"}
                  />
                  <ConfirmSection
                    label={"HR Burnout Certification Course"}
                    fontFamily={"Futura"}
                    isConfirmed={optionCertificationCourse}
                    onToggleConfirm={(val) => {
                      this.setState({optionCertificationCourse: val})
                      this.hapticIfNative()
                    }}
                    isDisabled={signUpInProgress}
                    marginTop={"2vh"}
                    color={"#000000"}
                  />
                  <ConfirmSection
                    label={"Team Live+Online Workshops"}
                    fontFamily={"Futura"}
                    isConfirmed={optionLiveTeamWorkshops}
                    onToggleConfirm={(val) => {
                      this.setState({optionLiveTeamWorkshops: val})
                      this.hapticIfNative()
                    }}
                    isDisabled={signUpInProgress}
                    marginTop={"2vh"}
                    color={"#000000"}
                  />
                  <ConfirmSection
                    label={"Executive Coaching"}
                    fontFamily={"Futura"}
                    isConfirmed={optionExecutiveCoaching}
                    onToggleConfirm={(val) => {
                      this.setState({optionExecutiveCoaching: val})
                      this.hapticIfNative()
                    }}
                    isDisabled={signUpInProgress}
                    marginTop={"2vh"}
                    color={"#000000"}
                  />
                </div>
                <SendRequestButton
                  defaultContent={"REQUEST A DEMO"}
                  backgroundColor={"#000000"}
                  loadingText={"REQUESTING DEMO..."}
                  successText={"DEMO REQUESTED"}
                  requestState={optInResultState}
                  isDisabled={registerButtonDisabled}
                  onClick={() => {
                    this.onSignUpForTraining()
                  }}
                />
                <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "min(2vh, 10px)", marginBottom: "6vh"}}>
                  <span>{"By submitting this form, you agree to our "}</span>
                  <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                  <span style={{marginInlineEnd: "0.2em"}}>{" and "}</span>
                  <a style={{color: "#a5a8b0", fontFamily: "Futura"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                  <span>{". Thank you!"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{padding: 15, paddingTop: 30, backgroundColor: "#EDEEF2", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", color: "#c1c4d2", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
          <div style={{display: "inline-block"}}>
            <span style={{color: "#c1c4d2"}}>
              {`© NEUROFIT ${currentYear}`}
            </span>
            <span style={{display: "inline-block", margin: "0px 0.2em", fontFamily: "Futura Medium", fontSize: "min(4.5vw, 18px)"}}>
              {"•"}
            </span>
            <span style={{color: "#c1c4d2"}}>
              {"ALL RIGHTS RESERVED"}
            </span>
          </div>
        </div>
        <TeamsOptInSuccessModal
          show={showOptInSuccessModal}
          firstName={firstName}
          innerHeight={innerHeight}
          safeAreaInsets={safeAreaInsets}
          onClose={() => this.setState({showOptInSuccessModal: false})}
        />
      </div>
    )
  }
}

export default TeamOptInFormDetails