import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import CoachAgentsWaitListDetails from './common/CoachAgentsWaitListDetails'

import { history } from '../history'

import {
  AppleSvg,
  AndroidSvg,
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import {
  GA_LABEL_CLICKED_REGISTER_FOR_MASTER_COACH_WAITLIST
} from '../constants/gaEventLabels'

import {
  REQ_SUBMIT_CERTIFICATION_VIDEO_PAGE_DETAILS,
} from '../constants/requestTypes'

import {
  EXPERIMENT_FLAGS_WEB_CERT_OFFER_USE_VSL,
} from '../constants/experimentFlags'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'

import {
  subdomainUrls,
  recordLinkClickGA,
  ProductionCertificationRegistrationUrl,
  NeuroFitUniversalAppStoreLink,
  NeuroFitAppleAppStoreLink,
  NeuroFitGooglePlayStoreLink,
  CertificationCheckoutLink,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  identifyCertificationAnalyticsWebUser,
  configureAnalyticsUserProperties,
  getPixelParams,
  recordFunnelEventGA,
  delay,
} from '../utils'

import Helmet from './common/Helmet'

import agent from '../agent'

class CoachAgentsWaitListPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      headerBackgroundColor: "transparent",
      dropShadowColor: "transparent",
      topPageScrollProgress: 0,
      headerBorderColor: "transparent",
      headerBackgroundBlur: "none",
      heroBackdropFilter: "none",
      heroOverlayColor: "transparent",
      logoOutlineColor: "#EDEEF2",
      registerResultState: REQUEST_UNSTARTED,
      attemptedDuplicateOptIn: false
    }
  }

  async componentWillMount() {
    await this.signUpForTraining()
  }
  
  async signUpForTraining(requestBody) {
    const {experimentFlags} = this.props

    this.setState({...this.state, registerResultState: REQUEST_FETCHING})
    try {
      // const requestResult = await agent.NeuroFitBackend.submitCoachAgentsWaitlistRegistration(requestBody)
      await delay(1500)
      this.setState({...this.state, registerResultState: REQUEST_SUCCESS})

    } catch (err) {
      this.setState({...this.state, registerResultState: REQUEST_ERROR})
    }
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      isIOSDevice,
      isAndroidDevice,
      isNasmPage,
      requestResults,
      onTriggerRequest,
      onUpdateRequestState,
      experimentFlags
    } = this.props

    const {headerBorderColor, attemptedDuplicateOptIn, headerBackgroundColor, headerBackgroundBlur, logoOutlineColor, textShadow, topPageScrollProgress, registerResultState} = this.state
    
    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={"NEUROFIT Agents Waitlist"}
          description={"Waitlist for NEUROFIT Coaching Agents."}
          href={ProductionCertificationRegistrationUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#FFFFFF"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, borderBottom: `2px ${headerBorderColor} solid`, display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: headerBackgroundColor, zIndex: 1000, backdropFilter: headerBackgroundBlur, WebkitBackdropFilter: headerBackgroundBlur}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              dropShadowColor={logoOutlineColor}
            />
          </div>
          <CoachAgentsWaitListDetails
            innerHeight={innerHeight}
            safeAreaInsets={safeAreaInsets}
            isNative={isNative}
            attemptedDuplicateOptIn={attemptedDuplicateOptIn}
            onSignUpForTraining={async (body) => {
              await this.signUpForTraining(body)
            }}
            signUpForTrainingRequestResult={{state: registerResultState}}
            onContainerScroll={updatedState => this.setState({...updatedState})}
            experimentFlags={experimentFlags}
          />
        </div>
      </div>
    )
  }
}

export default CoachAgentsWaitListPage;