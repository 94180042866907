import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  resetCoachAgentsWaitListPage,
  certificationVideoPageUpdateRequestState,
  certificationVideoPageTriggerRequest,
} from '../actions/certificationVideoPage';

import {
  toggleBodyScroll,
  hideLoadingScreen,
  initializeGA,
  pageViewGA,
  recordSignupFunnelEventGA,
  getAdvertisementIdFromQueryString,
  setAdvertisementReferralUserProperty,
  isGDPRSensitive
} from '../utils'

import CoachAgentsWaitListPage from '../components/CoachAgentsWaitListPage';

import {
  REQUEST_UNSTARTED,
} from '../constants/requestStates'

import {
  COMMON_INNER_HEIGHT,
  COMMON_EXPERIMENT_FLAGS_WEB,
  COMMON_SAFE_AREA_INSETS,
  COMMON_IS_NATIVE,
  COMMON_IS_IOS_DEVICE,
  COMMON_IS_ANDROID_DEVICE,
} from '../constants/commonState'

import {
  FORM_FIELD_HIDE_INTRO_VIDEO,
} from '../constants/formFields'

import {
  GA_LABEL_CLICKED_REGISTER_FOR_MASTER_COACH_WAITLIST,
  GA_LABEL_LOADED_MASTER_COACH_WAITLIST_PAGE,
} from '../constants/gaEventLabels'

class CoachAgentsWaitListPageContainer extends Component {
  async componentWillMount() {
    pageViewGA();
    await recordSignupFunnelEventGA(GA_LABEL_LOADED_MASTER_COACH_WAITLIST_PAGE)
  }

  render() {
    return (
      <CoachAgentsWaitListPage
        // State
        innerHeight={this.props.innerHeight}
        safeAreaInsets={this.props.safeAreaInsets}
        isIOSDevice={this.props.isIOSDevice}
        isAndroidDevice={this.props.isAndroidDevice}
        iOSAppIsAvailable={this.props.iOSAppIsAvailable}
        androidAppIsAvailable={this.props.androidAppIsAvailable}
        experimentFlags={this.props.experimentFlags}
        // Actions
      />
    );
  }
}

CoachAgentsWaitListPageContainer.propTypes = {
  experimentFlags: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  innerHeight: PropTypes.number.isRequired,
  isIOSDevice: PropTypes.bool.isRequired,
  isAndroidDevice: PropTypes.bool.isRequired,
  safeAreaInsets: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  experimentFlags: state.common[COMMON_EXPERIMENT_FLAGS_WEB],
  innerHeight: state.common[COMMON_INNER_HEIGHT],
  isIOSDevice: state.common[COMMON_IS_IOS_DEVICE],
  isAndroidDevice: state.common[COMMON_IS_ANDROID_DEVICE],
  safeAreaInsets: state.common[COMMON_SAFE_AREA_INSETS],
});

export default connect(mapStateToProps)(CoachAgentsWaitListPageContainer);
